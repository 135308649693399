let resumeData = {
  imagebaseurl: "",
  name: "Dani",
  role: "Software Developer",
  linkedinId: "@dani-santos-code",
  skypeid: "@cookiecut",
  roleDescription:
    "I love working in web app projects that provide solutions to enhance people's lives and strengthen communities. I like to learn about new technologies and attend interesting tech-related conferences.",
  socialLinks: [
    {
      name: "linkedin",
      url: "https://www.linkedin.com/in/dani-santos-code/",
      className: "fa fa-linkedin"
    },
    {
      name: "github",
      url: "https://github.com/dani-santos-code",
      className: "fa fa-github"
    },
    {
      name: "skype",
      url: "https://twitter.com/danisantoscode",
      className: "fa fa-twitter"
    }
  ],
  aboutme1: "In the past years, I've been working on infrastructure security projects at one of the ",
  aboutmelink1:
    "largest e-commerce companies in the world. ",
  aboutme2:
    "I've been focusing on Kubernetes resources management, Google Cloud infrastructure as code using Terraform, and IAM management. Most of the projects I've worked on recently are written in GoLang. Prior to that, I attended the ",
  aboutmelink2:
    "Full-Stack Web Development coding bootcamp at Concordia University ",
  aboutme3: "in Montréal, focusing on Node/React. I also studied Software Engineering at ",
  aboutmelink3: "Code University of Applied Sciences ",
  aboutme4:
    "in Berlin. I have over 3 years of hands-on experience in agile environments, following software engineering best practices.  So I'm an experience-driven developer, curious to the bone. Born in Brazil, I moved to Montréal in 2020. I'm passionate about technology and innovation. I also like learning new languages, not only human but computer ones. 🤖",
  address: "Montréal, QC, Canada",
  email: "santos.adaniele@gmail.com",
  website: "https://github.com/dani-santos-code",
  education: [
    {
      UniversityName: "Concordia University",
      link: "https://concordiabootcamps.ca/",
      specialization: "Full-Stack Immersive Diploma",
      MonthOfPassing: "Jan. 2020",
      YearOfPassing: "Present",
      Achievements:
        "I have worked on several projects which can be found on my Github."
    },
    {
      UniversityName: "Code University of Applied Sciences Berlin",
      link: "https://code.berlin/en/study/software-engineering/",
      specialization: "BS.c Software Engineering (incomplete)",
      MonthOfPassing: "2017",
      YearOfPassing: "2019",
      Achievements:
        "I was a Teaching Assistant in Foundations of Software Engineering in the 4th semester."
    },
    {
      UniversityName: "Udacity",
      link:
        "https://www.udacity.com/course/full-stack-web-developer-nanodegree--nd0044",
      specialization: "Full Stack Web Developer Nanodegree (SQL, Python/Flask)",
      MonthOfPassing: "Jan 2019",
      YearOfPassing: "April 2019",
      Achievements:
        "Created a music inventory app, created an instance on AWS Lighsail and deployed it."
    }
  ],
  work: [
    {
      CompanyName: "Shopify",
      link: "https://www.shopify.com/",
      specialization: "Infrastructure Security Engineeer",
      MonthOfLeaving: "December 2020",
      YearOfLeaving: "current",
      Achievements:
        "After working as an intern, I was promoted to a full-time role, where I've had the opportunity to work on different aspects of the security of the company's systems, such as Kubernetes runtime security with Falco, Google Cloud Identity Access Management, Binary Authorization (Code Signing), Infrastructure as Code via Terraform, as well as key encryption strategies."
    },
    {
      CompanyName: "Shopify",
      link: "https://www.shopifycom/",
      specialization: "Backend Developer Intern (GoLang)",
      MonthOfLeaving: "May 2020",
      YearOfLeaving: "Dec. 2020",
      Achievements:
        "While interning, I had the opportunity to contribute to many projects, including open sources ones such as Shopify/kubeaudit and grafeas/voucher. I also had the opportunity to work on full-stack apps written in Ruby on Rails as well as React."
    },
    {
      CompanyName: "Trifacta",
      link: "https://www.trifacta.com/",
      specialization: "Software Engineering Intern (Node.js)",
      MonthOfLeaving: "July 2019",
      YearOfLeaving: "Dec. 2019",
      Achievements:
        "Participated in the successful delivery of new features to help our Support Team provide better service to over 12.000 Companies (Google, Bosch, Bell, Pepsico, etc)."
    },
    {
      CompanyName: "Code University Berlin",
      link: "https://code.berlin/en/",
      specialization:
        "Teaching Assistant in Software Engineering (Focus on Backend with Python)",
      MonthOfLeaving: "Dec. 2018",
      YearOfLeaving: "May 2019",
      Achievements:
        "Gave a 4-hour workshop on Javascript supervised by prof. Dr. Adam Roe."
    },
    {
      CompanyName: "Campanda",
      link: "https://www.campanda.com/",
      specialization: "Backend Developer Intern (Focus on PHP)",
      MonthOfLeaving: "Dec 2018",
      YearOfLeaving: "Oct. 2018",
      Achievements:
        " Implemented the first phase of the integration of the Database with Google Sheets API to help the Marketing team provide better customer support, leading to a boost in the sales of more than 5 marketplaces."
    },
    {
      CompanyName: "Factory Berlin",
      link: "https://factoryberlin.com/",
      specialization: "Full-stack Developer Intern (Focus on React.js)",
      MonthOfLeaving: "Oct 2018",
      YearOfLeaving: "Feb 2018",
      Achievements:
        "Search tool successfully delivered for the Factory community (of over 2000 members) in October 2018"
    }
  ],
  skillsDescription: "Some of the tools I've been using:",
  skills: [
    {
      skillname: "GCP"
    },
    {
      skillname: "GoLang"
    },
    {
      skillname: "Kubernetes"
    },
    {
      skillname: "Javascript"
    },
    {
      skillname: "RubyOnRails"
    }
  ],
  portfolio: [
    {
      name: "A Day For Susana",
      description: "Landing Page to promote the Documentary",
      imgurl: "images/portfolio/susana.png"
    },
    {
      name: "project2",
      description: "mobileapp",
      imgurl: "images/portfolio/project.jpg"
    },
    {
      name: "project3",
      description: "mobileapp",
      imgurl: "images/portfolio/project2.png"
    },
    {
      name: "project4",
      description: "mobileapp",
      imgurl: "images/portfolio/phone.jpg"
    }
  ],
  testimonials: [
    {
      description:
        "I had the pleasure to work with Daniele at Factory Berlin. She worked super independent and pro-actively and grew super fast into her role.",
      name: "Michael Weiberger. Lead Experience Designer @ Lufthansa Group"
    }
  ]
};

export default resumeData;
