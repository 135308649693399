import React, { Component } from "react";
export default class About extends Component {
  render() {
    let resumeData = this.props.resumeData;
    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img className="profile-pic" src="images/dani.jpg" alt="" />
          </div>

          <div className="nine columns main-col">
            <h2>About Me</h2>
            <p>
              {resumeData.aboutme1}
              <a
                href="https://www.shopify.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                {resumeData.aboutmelink1}
              </a>
              {resumeData.aboutme2}
              <a
                href="https://code.berlin/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {resumeData.aboutmelink2}
              </a>
              {resumeData.aboutme3}
              <a
                href="https://code.berlin/en/"
                target="_blank"
                rel="noopener noreferrer"
              >
                {resumeData.aboutmelink3}
              </a>
              {resumeData.aboutme4}
            </p>

            <div className="row">
              <div className="columns contact-details">
                <h2>Want to know more?</h2>
                <p className="address">
                  <span>
                    <b>{resumeData.name}</b>
                  </span>
                  <br></br>
                  <span>{resumeData.email}</span>
                  <br></br>
                  <span>{resumeData.address}</span>
                  <br></br>
                  <span>{resumeData.website}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
